import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './hero.module.css'

const Hero = ({ image, title, content }) => (
  <div>

<div className="flex justify-center  max-w-3xl  mx-auto">
          {image && (
            <GatsbyImage className="object-fill w-full h-96 " alt={title} image={image} />
          )}
      </div>
  <div className="flex flex-col max-w-3xl  mx-auto pb-6">
    <h1 className="text-h1 font-semibold pt-6 ">{title}</h1>
    {content && <p className="">{content}</p>}
  </div>



  </div>



)

export default Hero
